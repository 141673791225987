(function () {
    const toggleHiddenList = (e) => {
        e.currentTarget.parentNode.parentNode
            .querySelector('[data-ul-collapsible-hidden]').classList.toggle('show');
    };
    const init = (root) => {
        const visibleList = root.querySelector('[data-ul-collapsible-visible]');
        const hiddenList = root.querySelector('[data-ul-collapsible-hidden]');
        const moreButton = root.querySelector('[data-ul-collapsible-more]');
        const entries = [];
        const moreButtonWidth = moreButton.offsetWidth;
        visibleList.removeChild(moreButton);
        const collectEntries = (ul) => {
            ul.querySelectorAll('li').forEach((li) => {
                li.parentNode.removeChild(li);
                entries.push(li);
            });
        };
        collectEntries(visibleList);
        collectEntries(hiddenList);
        let width = 0;
        const containerWidth = root.offsetWidth;
        while (entries.length > 0) {
            const li = entries.shift();
            visibleList.appendChild(li);
            width = width + li.offsetWidth;
            if (window.innerWidth > 767 && containerWidth > 0 && containerWidth - moreButtonWidth < width) {
                visibleList.removeChild(li);
                hiddenList.appendChild(li);
                while (entries.length > 0) {
                    hiddenList.appendChild(entries.shift());
                }
            }
            visibleList.appendChild(moreButton);
            if (hiddenList.querySelectorAll('li').length > 0 ) {
                moreButton.style.display = 'block';
                hiddenList.style.display = 'block';
            } else {
                hiddenList.style.display = 'none';
                moreButton.classList.remove('show');
                moreButton.style.display = 'none';
            }
        }
        moreButton.removeEventListener('click', toggleHiddenList);
        moreButton.addEventListener('click', toggleHiddenList);
    };
    window.addEventListener('load',  () => {
        document.querySelectorAll('[data-ul-collapsible]').forEach(init);
    });
    window.addEventListener('resize', () => {
        document.querySelectorAll('[data-ul-collapsible]').forEach(init);
    });
})();
