(function () {
    const init = (root) => {
        let currentImage = -1;
        const play = () => {
            const images = root.querySelectorAll('[data-slide-show-image]');
            let next = currentImage + 1;
            if (next === images.length) {
                next = 0;
            }
            if (currentImage > -1) {
                images[currentImage].classList.remove('visible');
            }
            images[next].classList.add('visible');
            currentImage = next;
            setTimeout(play, 3000);
        };
        play();
    };
    window.addEventListener('load', () => {
        document.querySelectorAll('[data-images-carousel]').forEach(init);
    });
}());
